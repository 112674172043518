import { CarrierProfileTab } from 'features/carrierCapacity/CapacityTypes';
import { Drawer, SecondaryNavigation, TextInput } from 'shamrock-clover-ui';
import styled from 'styled-components';

export const DrawerTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  > p {
    font-size: 24px;
    margin: 0;
  }
  padding: 16px 24px 16px 40px;
`;

export const DrawHeader = styled.div`
  display: flex;
  padding: 0 24px 0 40px;
  justify-content: space-between;
  border-top: 1px solid #999999;
  border-bottom: 1px solid #999999;
  align-items: center;
  height: 76px;
  position: relative;
  background: #ffffff;
  z-index: 100;
  > span {
    color: #fff;
  }
`;

export const ContentContainer = styled.div<{ successfullState?: boolean }>`
  padding: 16px 24px 16px 40px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin-top: 78px;
  overflow-y: auto;
  height: ${(props) => (props.successfullState ? '80vh' : '100vh')};
`;

export const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  > span {
    font-size: 14px;
  }
`;

export const PaginationButton = styled.div`
  height: 41px;
  width: 41px;
  border-radius: 50%;
  background-color: #f9f9f9;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  > div {
    cursor: pointer;
  }
  &:hover {
    background-color: #eaf7ff;
  }
`;

export const DrawerContentNavigation = styled(SecondaryNavigation)<{
  drawerTabClicked: CarrierProfileTab;
}>`
  margin: 0px
  padding: 8px 0px;
  border-bottom: 0;
  height: 17px;
  color: green !important;
  #smr-secondary-navigation-element-0 {
    font-weight: ${(props) => (props.drawerTabClicked === 'Details' ? 900 : 500)}
  }
  #smr-secondary-navigation-element-1 {
    font-weight: ${(props) => (props.drawerTabClicked === 'Load History' ? 900 : 500)}
  }
`;

export const StyledTextContainer = styled.div`
  justify-content: center;
  height: 70px;
  display: flex;
  align-items: center;
`;

export const AddLaneForm = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

export const InputsGroup = styled.div<{ gap?: number }>`
  display: flex;
  padding-right: 20px;
  gap: ${(props) => (props.gap ? props.gap + 'px' : '4px')};
  margin-top: 10px;
  padding-right: 0;
`;

export const InputsTitles = styled.span`
  font-weight: 700;
  font-size: 16px;
`;

export const ResultText = styled.div`
  font-size: 18px;
  font-weight: 400;
  color: #333333;
  text-align: center;
`;

export const AddLaneAndCapacityContent = styled.div<{
  separator?: boolean;
  marginTop?: string;
}>`
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '15px')};
  margin-bottom: 25px;
  padding-bottom: ${(props) => props.separator && '20px'};
  border-bottom: ${(props) => props.separator && '1px solid #cccccc'};
`;

export const CapacityText = styled.span`
  font-weight: 400;
  font-size: 14px;
  display: block;
  color: #333333;
  padding-top: 10px;
  padding-bottom: 5px;
`;

export const CustomDrawer = styled(Drawer)`
  & > div:last-child {
    overflow-y: auto;
  }

  & > div:first-child > div:last-child {
    z-index: 99999;
  }
`;

export const StyledAutosuggestWrapper = styled.div`
  position: relative;
  width: 255px;
  font-size: 16px;
  font-weight: 400;
  background: #eeeeee;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: 1px solid #cccccc;
  && .searcLocationsInputs__input,
  && .searcLocationsInputs__input:focus,
  && .searcLocationsInputs__input:focus-visible {
    border: none;
    outline: none;
    box-shadow: none;
    top: 12px;
  }
  &:focus-within {
    border-bottom: 2px solid #0091ea;
  }
  div {
    div {
      &:focus-within label,
      .form__field:focus ~ .form__label {
        color: #0091ea !important;
      }
    }
  }
`;

export const StyledInput = styled.input`
  padding: 10px;
  font-family: ${(props) => props.theme.font.family.proximaNova};
  font-size: 16px;
  font-weight: 400;
  position: absolute;
  top: 7px;
  color: #666666;
  background: transparent;
`;

export const StyledSuggestionsContainer = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: white;
  border-top: none;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 1;
  ul {
    padding: 0;
    list-style: none;
    margin: 0;
    font-family: ${(props) => props.theme.font.family.proximaNova};
    font-size: 14px;
    font-weight: 400;
  }
`;

export const StyledSuggestion = styled.div`
  padding: 10px;
  cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
  }
`;

export const StyledInputContainer = styled.div`
  position: relative;
  width: 255px;
  font-size: 16px;
  font-weight: 400;
  background: #eeeeee;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  .floating-label {
    position: absolute;
    top: 16px;
    left: 15px;
    color: #999999;
    font-size: 16px;
    font-weight: 400;
    transition: all 0.2s ease;
  }

  &:focus-within .floating-label,
  &.has-value .floating-label {
    top: 6px;
    left: 9px;
    font-size: 12px;
    color: #666666;
  }
`;

export const SelectInputContainer = styled.div<{
  error?: boolean;
  isEmpty?: boolean;
  isEmptyState?: boolean;
}>`
  .select-error {
    font-family: proxima-nova, arial, sans-serif;
    margin: 5px 0px 0px 10px;
    color: #ef3824;
    font-size: 0.75em;
    background-color: transparent;
  }
  .inputContainer {
    border-bottom: ${(props) => props.error && '1px solid red'};
    padding-bottom: ${(props) =>
      (props.isEmpty && '15px') || (props.isEmptyState && '16px')};
  }
`;

export const TextInputStyled = styled(TextInput)`
  div {
    div {
      &:focus-within label,
      .form__field:focus ~ .form__label {
        color: ${({ error, theme }) => (error ? theme.red : theme.blue)};
      }
    }
  }
`;

export const OptionalText = styled.span`
  position: relative;
  font-size: 12px;
  margin: 5px 0 0 10px;
  color: #666666;
`;

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 172px;
`;

export const HeaderText = styled.div`
  font-size: 24px;
  font-weight: 600;
`;

export const ColumnContainer = styled.div<{
  height?: string;
  width?: string;
  borderRight: boolean;
  padding?: string;
}>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: ${({ height }) => height};
  width: ${({ width }) => width};
  border-right: ${({ borderRight }) =>
    borderRight ? '1px solid #BBBBBB' : 'none'};
  padding: ${({ padding }) => padding};
`;

export const InfoContainer = styled.div<{}>`
  display: flex;
  flex-direction: row;
`;

export const InfoColumn = styled.div<{ width: string; height: string }>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
`;

export const KeysText = styled.div<{ marginBottom?: string }>`
  font-size: 16px;
  font-weight: 400;
  margin-bottom: ${({ marginBottom }) => (marginBottom ? marginBottom : '3px')};
`;

export const ValuesText = styled.div<{
  marginBottom?: string;
  onClick?: () => void;
  onHover?: boolean;
}>`
  font-size: 16px;
  font-weight: 700;
  margin-bottom: ${({ marginBottom }) => (marginBottom ? marginBottom : '3px')};
  color: ${({ color }) => (color ? color : 'black')};
  ${({ onHover }) =>
    onHover &&
    `&:hover{
    background-color: blue;
  }`}'
`;

export const QuickClipboardCopy = styled.span`
  cursor: pointer;
  &:hover {
    background-color: #eaf7ff;
  }
`;

