import { CarrierCapacityContext } from 'features/carrierCapacity/context/CarrierCapacityContext';
import { useContext } from 'react';
import { MultiSelectMenu } from 'shamrock-clover-ui';
import { StyledFilterText, StyledIcon, StyledLanesFilterContainer, StyledMultiSelectContainer } from './DetailsTableStyles';

const LanesFilter = () => {
  const { handleSelectedFilterMethod, selectedFilterMethod, filterOptions } =
    useContext(CarrierCapacityContext);

  return (
    <StyledMultiSelectContainer>
      <MultiSelectMenu
        VisualElement={
          <StyledLanesFilterContainer>
            <StyledFilterText>
              Lane history: {selectedFilterMethod}
            </StyledFilterText>
            <StyledIcon icon="dropdownDown" size="12" />
          </StyledLanesFilterContainer>
        }
        onOptionSelected={(optionText, result) =>
          handleSelectedFilterMethod(optionText, result)
        }
        menuOptions={filterOptions}
        menuLocation="below"
        fontSize="14"
      />
    </StyledMultiSelectContainer>
  );
};

export default LanesFilter;
